import { ImageTemplate } from "../template";

import adBannersImage from "./ad-banners.png";
import affiliteTransactionsImage from "./affiliate-transactions.png";
import affiliatesImage from "./affiliates.png";
import approvedPartnersImage from "./approved-partners.png";
import billingImage from "./billing.png";
import bitcoinImage from "./bitcoin.png";
import campaignsImage from "./campaigns.png";
import cloTransactionsImage from "./clo-transactions.png";
import cloImage from "./clo.png";
import dashboardImage from "./dashboard.png";
import decryptImage from "./decrypt.png";
import giftCardsImage from "./gift-card.png";
import giftCardTransactionImage from "./affiliate-transactions.png";
import giftCardBagsImage from "./bags.png";
import interacImage from "./interac.png";
import manualImage from "./manual.png";
import missingTransactionsImage from "./missing-transactions.png";
import offersImage from "./offers.png";
import partnersImage from "./partners.png";
import pendingImage from "./pending.png";
import promotionsAmountsImage from "./promotions-amounts.png";
import healthImage from "./health.png";
import storylyImage from "./storyly.png";
import travelImage from "./travel.png";
import userProfileImage from "./user-profile.png";
import usersImage from "./users.png";
import premiumImage from "./premium-star.png";
import brokerImage from "./broker.png";
import walletImage from "./wallet.png";
import referralsImage from './referrals.png';
import securityImage from './siren.png';
import zombieImage from './zombie.png';

export type MenuItemProps = {
  width: number | string;
  height: number | string;
  type: string;
  highResolution?: boolean;
};

export const MenuItemImage = ({
  height = 38,
  width = 38,
  type,
  ...props
}: MenuItemProps): JSX.Element => {
  const image = {
    adBanners: adBannersImage,
    affiliateTransactions: affiliteTransactionsImage,
    affiliates: affiliatesImage,
    approvedPartners: approvedPartnersImage,
    billing: billingImage,
    bitcoin: bitcoinImage,
    campaigns: campaignsImage,
    cloTransactions: cloTransactionsImage,
    clo: cloImage,
    dashboard: dashboardImage,
    decrypt: decryptImage,
    giftCards: giftCardsImage,
    giftCardTransactions: giftCardTransactionImage,
    giftCardBags: giftCardBagsImage,
    interac: interacImage,
    manual: manualImage,
    missingTransactions: missingTransactionsImage,
    offers: offersImage,
    partners: partnersImage,
    pending: pendingImage,
    promotionsAmounts: promotionsAmountsImage,
    health: healthImage,
    storyly: storylyImage,
    travel: travelImage,
    userProfile: userProfileImage,
    users: usersImage,
    premiumAccounts: premiumImage,
    brokerTransactions: brokerImage,
    walletTransactions: walletImage,
    referrals: referralsImage,
    security: securityImage,
    zombie: zombieImage,
  }[type];

  return (
    <div style={{ height, width }}>
      <ImageTemplate {...props} imgSrc={image!} />
    </div>
  );
};
